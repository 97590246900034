<template>
    <div>
        <!-- 顶部 -->
        <el-row>
            <el-col :span='24'>
                <el-form :inline='true'>
                    <el-form-item label='关键字:'>
                        <el-input v-model.trim='filters.title' placeholder='请输入标题' clearable @clear='getData' @input='() =>(filters.title = filters.title.replace(/\s/g,""))' @keyup.enter.native='getAnnouncementListPage'></el-input>
                    </el-form-item>
                    <el-form-item label='信息类型:'>
                        <el-select v-model="filters.informationType" placeholder="请选择" clearable @change='getAnnouncementListPage'>
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='是否置顶:'>
                        <el-select v-model="filters.isSetTop" placeholder="请选择" clearable @change='getAnnouncementListPage'>
                            <el-option
                            v-for="item in options2"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='启用标识:'>
                        <el-select v-model="filters.isEnable" placeholder="请选择" clearable @change='getAnnouncementListPage'>
                            <el-option
                            v-for="item in options1"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item> 
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>                 
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table
        :data="tableData"
        border
        style="width: 95%"
        highlight-current-row
        @current-change='selectCurrentRow'
        v-loading='listLoading'>
            <!-- <el-table-column  prop="SortNo" label="序号" width="55" align='center'></el-table-column> -->
            <el-table-column  prop="Title" label="标题" width="" align='center' show-overflow-tooltip></el-table-column>
            <el-table-column  prop="Content" label="内容" width="" align='center' show-overflow-tooltip></el-table-column>   
            <el-table-column  prop="Remarks" label="简介" width="" align='center' show-overflow-tooltip></el-table-column>       
            <el-table-column  prop="InformationType	" label="公告类型" width="" align='center'>
                <template slot-scope='scope'>
                    <el-tag type='danger' v-if='scope.row.InformationType == 1'>通知公告</el-tag>
                    <el-tag type='warning' v-if='scope.row.InformationType == 2'>培训动态</el-tag>
                    <el-tag type='success' v-if='scope.row.InformationType == 3'>法规要求</el-tag>
                </template>
            </el-table-column>
            <el-table-column  prop="IsSetTop" label="是否置顶" width="" align='center'>
                <template slot-scope='scope'>
                    <el-tag :type='scope.row.IsSetTop==true?"success":"warning"'>{{scope.row.IsSetTop==true?'是':'否'}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column  prop="IsHtml" label="是否html" width="" align='center'>
                <template slot-scope='scope'>
                    <el-tag :type='scope.row.IsHtml==true?"success":"warning"'>{{scope.row.IsHtml==true?'是':'否'}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column  prop="CoverPhotoWebUrl" label="封面图片" width="" align='center'>
                <template slot-scope='scope'>
                    <el-popover placement='right' trigger='hover'>
                        <img :src="scope.row.CoverPhotoWebUrl" alt="" style='width:150px;height:150px'> 
                        <img slot="reference" :src="scope.row.CoverPhotoWebUrl" alt="" style='width: 36px;height: 36px;'>                   
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column  prop="IsEnable" label="启用标识" width="" align='center'>
                <template slot-scope='scope'>
                    <el-tag :type='scope.row.IsEnable==true?"success":"warning"'>{{scope.row.IsEnable==true?'启用':'禁用'}}</el-tag>
                </template>
            </el-table-column>            
            <el-table-column  prop="CreateTime" label="创建时间" width="200" align='center' :formatter="formatCreateTime"></el-table-column>
            <el-table-column  prop="CreateUserID" label="创建人" width="" align='center'></el-table-column>
            <el-table-column label='操作' width='200' align='center' fixed="right">
                <template slot-scope='scope'>
                    <el-button icon='el-icon-notebook-1' type='primary' @click='see(scope.row.ID)' size='mini' plain>查看</el-button>
                    <el-button type='danger' @click='setTop(scope.row.ID)' size='mini' v-if='!scope.row.IsSetTop'>置顶</el-button>
                    <el-button type='primary' @click='setTop(scope.row.ID)' size='mini' v-else>取消</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 新增/编辑 -->
        <el-dialog
        :title="isAdd?'新增':'编辑'"
        :visible.sync="dialogVisible"
        :close-on-click-modal='false'
        width="50%"
        @close='handleClose'>
        <div>
            <el-form :model="form" :rules="rules" ref="formRef" label-width="100px">
                <!-- <el-form-item label="序号：" prop="sortNo">
                    <el-input v-model="form.sortNo" @input='() => (form.sortNo = form.sortNo.replace(/\s/g,""))'></el-input>
                </el-form-item> -->
                <el-form-item label="标题：" prop="title">
                    <el-input v-model="form.title" @input='() => (form.title = form.title.replace(/\s/g,""))'></el-input>
                </el-form-item>
                <el-form-item label="公告类型" prop="informationType">
                    <el-select v-model="form.informationType" placeholder="请选择">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否html：" prop="isHtml">
                    <el-switch v-model="form.isHtml" active-color="#13ce66" inactive-color="#dcdfe6"></el-switch>
                </el-form-item>
                <el-form-item label="内容：" prop="content">
                    <el-input v-model="form.content" type='textarea' placeholder="请输入公告内容"></el-input>
                </el-form-item>   
                <el-form-item label="简介：" prop="remarks">
                    <el-input v-model="form.remarks" type='textarea' placeholder='请输入公告简介'></el-input>
                </el-form-item>            
                <el-form-item label="封面照片：" prop="coverPhotoUrl">
                    <el-upload
                    :action="action"
                    :headers="headers"
                    ref="upload"
                    :before-upload="beforeUpload"
                    :on-success="handleSuccess"
                    :show-file-list="false"
                    list-type="picture-card"
                    >
                        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                        <i v-else class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
                <!-- <el-form-item label="附件照片：" prop="annexs">
                    <el-upload
                        :action="action"
                        :headers="headers"
                        :file-list='fileList'
                        list-type="picture-card"
                        :before-upload="beforeUpload"
                        :on-success='handleSuccess1'
                        :on-error='handleError'
                        :on-remove="handleRemove1">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item> -->
                <el-form-item label="是否置顶：" prop="isSetTop">
                    <el-switch v-model="form.isSetTop" active-color="#13ce66" inactive-color="#dcdfe6"></el-switch>
                </el-form-item>
                <el-form-item label="是否启用：" prop="isEnable">
                    <el-switch v-model="form.isEnable" active-color="#13ce66" inactive-color="#dcdfe6"></el-switch>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submit" :loading='dialogLoading'>确 定</el-button>
        </span>
        </el-dialog>
        <!-- 分页 -->
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.pageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pages.dataCount">
        </el-pagination>
        <!-- 查看 -->
        <el-dialog
        title="查看"
        :visible.sync="isVisible"
        :close-on-click-modal='false'
        width="50%"
        @close='seeHandleClose'>
        <div>
            <el-form :model="seeForm"  label-width="100px" :disabled='true'>
                <!-- <el-form-item label="序号：" prop="sortNo">
                    <el-input v-model="seeForm.SortNo"></el-input>
                </el-form-item> -->
                <el-form-item label="标题：" prop="title">
                    <el-input v-model="seeForm.Title"></el-input>
                </el-form-item>
                <el-form-item label="是否html：" prop="isHtml">
                    <el-switch v-model="seeForm.IsHtml" active-color="#13ce66" inactive-color="#dcdfe6"></el-switch>
                </el-form-item>
                <el-form-item label="内容：" prop="content">
                    <el-input v-model="seeForm.Content"></el-input>
                </el-form-item>   
                <el-form-item label="简介：" prop="remarks">
                    <el-input v-model="seeForm.Remarks" type='textarea'></el-input>
                </el-form-item>            
                <el-form-item label="公告类型" prop="informationType">
                    <el-select v-model="seeForm.InformationType" placeholder="请选择">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="封面照片：" prop="coverPhotoUrl">
                    <el-upload
                    :action="action"
                    :headers="headers"
                    ref="upload"
                    :before-upload="beforeUpload"
                    :on-success="handleSuccess"
                    :show-file-list="false"
                    list-type="picture-card"
                    >
                        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                        <i v-else class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
                <!-- <el-form-item label="附件照片：" prop="annexs">
                    <el-upload
                        :action="action"
                        :headers="headers"
                        :file-list='fileList'
                        list-type="picture-card"
                        :before-upload="beforeUpload"
                        :on-success='handleSuccess1'
                        :on-error='handleError'
                        :on-remove="handleRemove1">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item> -->
                <el-form-item label="是否置顶：" prop="isSetTop">
                    <el-switch v-model="seeForm.IsSetTop" active-color="#13ce66" inactive-color="#dcdfe6"></el-switch>
                </el-form-item>
                <el-form-item label="是否启用：" prop="isEnable">
                    <el-switch v-model="seeForm.IsEnable" active-color="#13ce66" inactive-color="#dcdfe6"></el-switch>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="isVisible = false">取 消</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import Qs from 'qs';
import util from "../../../util/date";
import {getAnnouncementListPage,getAnnouncementByID,addAnnouncement,updateAnnouncement,deleteAnnouncement,setTopAnnouncement,uploadTempPicUrl} from '@/api/api'
export default {
    components:{Toolbar},
    data(){
        return {
            buttonList: [],
            filters:{
                title:'',
                informationType:'',
                isEnable:'',
                isSetTop:''               
            },
            options:[
                {label:'通知公告',value:1},
                {label:'培训动态',value:2},
                {label:'法规要求',value:3}

            ],
            options1:[
                {label:'启用',value:true},
                {label:'禁用',value:false}
            ],
            options2:[
                {label:'是',value:true},
                {label:'否',value:false}
            ],
            tableData:[],
            listLoading:false,
            isAdd:true,
            dialogVisible:false,
            dialogLoading:false,
            form:{
                // sortNo:'',
                sortNo:0,
                title:'',
                isHtml:false,
                content:'',
                informationType:'',
                coverPhotoUrl:'',
                annexs:[],
                remarks:'',
                isSetTop:false,
                isEnable:true
            },
            rules:{
                title:[{required:true,message:"请输入标题",trigger:'blur'}],
                content:[{required:true,message:'请输入内容',trigger:'blur'}],
                informationType:[{required:true, message:'请选择公告类型', trigger:'change'}],
                remarks:[{required:true,message:'请输入简介内容',trigger:'blur'}],
            },
            // 分页相关数据
            pages:{
                pageIndex:1,
                pageSize:20,
                dataCount:0
            },
            currrentRow:{},
            // 图片上传相关数据
            action:'',
            imageUrl:'', // 展示的图片
            fileList0:[],
            fileList:[], // 要上传的图片集合
            photoList:[],

            // 查看相关数据
            isVisible:false,
            seeForm:{}
        }
    },
    computed:{
        headers(){
            return {
                "Authorization": 'Bearer ' + localStorage.getItem('Token')
            }
        },
    },
    methods:{
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd hh:mm:ss");
        },
        // 获取列表数据
        getData(){
            var params = {
                title:this.filters.title,
                informationType:this.filters.informationType,
                isEnable:this.filters.isEnable,
                isSetTop:this.filters.isSetTop,
                pageIndex:this.pages.pageIndex,
                pageSize:this.pages.pageSize
            }
            getAnnouncementListPage(params).then(res => {
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.dataCount = res.data.Response.DataCount
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() => {})
        },
        // 表单提交
        submit(){
            this.$refs.formRef.validate((valid) =>{
                if(valid){
                    this.dialogLoading = true
                    if(this.isAdd){
                        var params = {...this.form}                        
                        this.addFormSubmit(params)
                    }else{
                        this.editFormSubmit()
                    }
                }
            })
        },
        addFormSubmit(params){
            addAnnouncement(params).then(res =>{
                this.dialogLoading = false
                this.dialogVisible = false
                if(res.data.Success){
                    this.$message.success(res.data.Message)
                    this.getData()
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() =>{
                this.dialogLoading = false
                this.dialogVisible = false
            })
        },
        editFormSubmit(){
            var params = {...this.form}
            params.id = this.currrentRow.ID
            updateAnnouncement(params).then(res =>{
                this.dialogLoading = false
                this.dialogVisible = false
                if(res.data.Success){
                    this.$message.success(res.data.Message)                  
                    this.getData()
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() =>{
                this.dialogLoading = false
                this.dialogVisible = false
            })
        },

        // 分页相关操作
        handleSizeChange(value){
            this.pages.pageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            window.sessionStorage.setItem('pagination',JSON.stringify(value))
            this.pages.pageIndex = value
            this.getData()
        },

        // 选择当前行
        selectCurrentRow(row){
            this.currrentRow = row
        },

        // 增删改查相关操作
        // 查询
        getAnnouncementListPage(){
            this.pages.pageIndex = 1
            this.getData()
        },
        // 新增
        handleAdd(){
            this.form = {
                sortNo:0,
                title:'',
                isHtml:false,
                content:'',
                informationType:'',
                coverPhotoUrl:'',
                annexs:[],
                remarks:'',
                isSetTop:false,
                isEnable:true
            }
            this.isAdd = true
            this.dialogVisible = true
        },
        // 编辑
        handleEdit(){
            var row = this.currrentRow
            if(JSON.stringify(row) == '{}'){
                this.$message.warning('请选择要编辑的数据')
            }else{
                this.isAdd = false
                this.form = {
                    sortNo:Number(row.SortNo),
                    title:row.Title,
                    isHtml:row.IsHtml,
                    content:row.Content,
                    informationType:row.InformationType,
                    coverPhotoUrl:row.CoverPhotoUrl,
                    annexs:row.Annexs,
                    remarks:row.Remarks,
                    isSetTop:row.IsSetTop,
                    isEnable:row.IsEnable
                }
                // 封面图片回显
                this.imageUrl = row.CoverPhotoWebUrl
                // 附件图片回显
                row.Annexs.forEach(item => {
                    var obj = {url:item.AnnexPhotoWebUrl}
                    this.fileList.push(obj)
                })
                this.dialogVisible = true               
            }
        },
        // 删除
        handleDel(){
            var row = this.currrentRow
            if(JSON.stringify(row) == '{}'){
                this.$message.warning('请选择要删除的一行数据')
            }else{
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() =>{
                    var params = {
                        id:row.ID
                    }
                    deleteAnnouncement(Qs.stringify(params)).then(res =>{
                        if(res.data.Success){
                            this.$message.success(res.data.Message)
                            this.getData()
                        }else{
                            this.$message.error(res.data.Message)
                        }
                    }).catch(() =>{})
                }).catch(() =>{})
            }
        },

        // 查看
        see(id){
            var params = {id}
            this.isVisible = true
            getAnnouncementByID(params).then(res =>{
                if(res.data.Success){
                    this.seeForm = res.data.Response
                    this.imageUrl = res.data.Response.CoverPhotoWebUrl
                    res.data.Response.Annexs.forEach(item =>{
                        var obj = {url:item.AnnexPhotoWebUrl}
                        this.fileList.push(obj)
                    })
                }
            })
        },
        // 置顶
        setTop(id){
            var params = {id}
            setTopAnnouncement(Qs.stringify(params)).then(res => {
                if(res.data.Success){
                    this.$message.success(res.data.Message)
                    this.getData()
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() =>{})
        },

        // 对话框关闭
        handleClose(){
            this.fileList = []
            this.imageUrl = ''
            this.$refs.formRef.resetFields()
            this.getData()
        },
        seeHandleClose(){
            this.imageUrl = ''
            this.fileList = []
        },

        // 图片上传相关操作
        // 图片上传之前
        beforeUpload(file){
            const isLt2M = file.size / 1024 / 1024 < 2;
            if(!isLt2M){
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isLt2M
        },
        // 图片上传成功
        handleSuccess(res,file,fileList){
            if(res.Success){
                this.$message.success(res.Message)
                this.imageUrl = res.Response.weburl
                this.form.coverPhotoUrl = res.Response.webupurl
            }else{
                this.$message.error(res.Message)
            }
        },
        handleSuccess1(res,file,fileList){         
            if(res.Success){
                this.$message.success(res.Message)
                var obj = {
                    url:res.Response.weburl
                }
                this.fileList.push(obj)
                this.form.annexs.push({annexPhotoUrl:res.Response.webupurl})
            }else{
                this.$message.error(res.Message)
            }
        },
        // 图片上传失败
        handleError(err,file,fileList){
            this.$message.error('上传失败')
        },
        // 移除图片
        handleRemove1(file,fileList){
            this.fileList = fileList
            this.form.annexs.forEach(item =>{
                if(item.AnnexPhotoWebUrl == file.url){
                    var a = this.form.annexs.indexOf(item)
                    this.form.annexs.splice(a,1)
                } 
            })  
        }
    },
    beforeRouteEnter(to,from,next){
        if(from.path.indexOf(to.path) == -1){
            window.sessionStorage.removeItem('pagination')
            next()
        }
    },
    created(){
        this.action = uploadTempPicUrl
        this.pages.pageIndex = Number(window.sessionStorage.getItem('pagination')) || 1
        this.getData()
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    .avatar-uploader .el-upload {
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 146px;
        height: 146px;
        line-height: 146px;
        text-align: center;
    }
    .avatar {
        width: 146px;
        height: 146px;
        display: block;
    }
    /deep/ .el-upload--picture-card{
        width:146px;
        height:146px;
        display:block;
    }
    /deep/ .el-textarea__inner{
        height:200px;
    }
</style>
<style>
    .el-tooltip__popper {
        max-width: 500px;
    }
</style>